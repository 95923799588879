import { useRef, FormEvent, FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { OldApi } from "../api/api";

interface IPopupContactUsProps {
  onClose: () => void;
}

const PopupContactUs: FC<IPopupContactUsProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const name = nameRef.current?.value;
    const email = emailRef.current?.value;
    const message = messageRef.current?.value;
    // const docInput = document.getElementById('document') as HTMLInputElement;

    if (name && email && message) {
      // formData.append('name', name);
      // formData.append('email', email);
      // formData.append('phone', phone);

      // const file = docInput.files[0];
      // formData.append('file', file);

      let response;
      if (file) {
        response = await await toast.promise(
          OldApi.contactUs({ email, name, message, file: file }),
          {
            pending: t("toast:promise.pending"),
            success: t("toast:promise.success"),
            error: t("toast:promise.error"),
          }
        );
      } else {
        response = await await toast.promise(
          OldApi.contactUs({ email, name, message }),
          {
            pending: t("toast:promise.pending"),
            success: t("toast:promise.success"),
            error: t("toast:promise.error"),
          }
        );
      }
      if (response.success) {
        onClose();
      } else {
        toast.error(t("toast:allFieldsMustBeFilledIn"));
      }
      // formData.append('name', name);
      // formData.append('email', email);
      // formData.append('phone', phone);

      // const file = docInput.files[0];
      // formData.append('file', file);
    }

    // if (name && email && message && docInput) {
    //   formData.append('name', name);
    //   formData.append('email', email);
    //   formData.append('message', message);

    //   if (docInput.files?.length) {
    //     const file = docInput.files[0];
    //     formData.append('file', file);
    //   }
    // }

    // onClose();
  };

  const handleBackgroundClick = (e: any) => {
    if (e.target.className === "popup") {
      onClose();
    }
  };

  return (
    <div className="popup-wrapper" onClick={handleBackgroundClick}>
      <input type="checkbox" className="popup-checkbox" id="popupCheckboxOne" />
      <div className="popup">
        <div className="popup-content">
          <label
            htmlFor="popupCheckboxOne"
            className="popup-closer"
            onClick={onClose}
          >
            &#215;
          </label>
          <div className="popup-view popup-view-contactUs">
            <div className="address-contact">
              <p>JellyWorkz</p>
              <p>Oleksandra Polia Ave, 58, Dnipro, Ukraine</p>
              <a href="tel:+38097454782">+38097454782</a>
            </div>
            <div className="address-contact-content">
              <h3>{t("contactUs:title")}</h3>
              <form method="post" onSubmit={handleSubmit}>
                <div className="form-row">
                  <input
                    placeholder=" "
                    type="text"
                    id="name"
                    name="name"
                    required
                    autoComplete="off"
                    ref={nameRef}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                      }
                    }}
                  />
                  <label htmlFor="name">{t("contactUs:name")} *</label>
                </div>
                <div className="form-row">
                  <input
                    placeholder=" "
                    type="email"
                    id="email"
                    name="email"
                    required
                    autoComplete="off"
                    ref={emailRef}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                      }
                    }}
                  />
                  <label htmlFor="email">{t("contactUs:email")} *</label>
                </div>
                <div className="form-row">
                  <input
                    placeholder=" "
                    type="text"
                    id="message"
                    name="message"
                    required
                    autoComplete="off"
                    ref={messageRef}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                      }
                    }}
                  />
                  <label htmlFor="message">
                    {t("contactUs:yourMessage")} *
                  </label>
                </div>
                <div className="add-document">
                  <input
                    type="file"
                    name="document"
                    id="document"
                    // @ts-ignore
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <label htmlFor="document">
                    {t("contactUs:attachDocument")}
                  </label>
                </div>
                {file && (
                  <div className="selected-document">
                    <span className="selected-document-text">{file.name}</span>
                    <span
                      onClick={() => setFile(null)}
                      className="selected-document-cross"
                    >
                      &#215;
                    </span>
                  </div>
                )}
                <div className="btn">
                  <button type="button" className="btn1" onClick={onClose}>
                    {t("contactUs:cancel")}
                  </button>
                  <button className="btn2">
                    <span className="btn2-span">{t("contactUs:send")}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupContactUs;
