import { FC } from 'react';
import { Footer } from './Footer';
import Header from './Header';

const Layout: FC = ({ children }) => (
  <div className="mainWrapper">
    <Header />
    {children}
    <Footer />
    <div className="background">
      <div className="box">
        <div className="jelly yellow" />
        <div className="jelly pink" />
        <div className="jelly green" />
      </div>
    </div>
  </div>
);

export default Layout