import { FC, MutableRefObject, useEffect, useMemo, useState } from "react";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { getCurrentLocale, locales } from "../i18n";
import PopupContactUs from "./PopupContactUs";
import { CSSTransition } from "react-transition-group";
import ActiveLink from "./ActiveLink";

interface INavigationProps {
  checked: boolean;
  handleClick: () => void;
  handleClose: () => void;
}

interface ILinkItem {
  onClick?: () => void;
  href?: string;
  text: string;
}

const Navigation: FC<INavigationProps> = ({
  checked,
  handleClick,
  handleClose,
}) => {
  const { t } = useTranslation();
  const currentLocale = getCurrentLocale();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isBrowser = typeof window !== "undefined";

  const languages = [
    { key: locales[1], text: "Укр" },
    { key: locales[0], text: "Eng" },
  ];

  const links: ILinkItem[] = useMemo(
    () => [
      { href: "/about", text: t("navigation:about") },
      { href: "/services", text: t("navigation:services") },
      { href: "/portfolio", text: t("navigation:portfolio") },
      { href: "/vacancies", text: t("navigation:vacancies") },
      { onClick: () => setIsPopupOpen(true), text: t("navigation:contactUs") },
      { href: "/blog", text: t("navigation:blog") },
    ],
    [t]
  );

  const handleLanguageClick = (language: string) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const newLinks = document.getElementsByClassName("link");
    for (let i = 0; i < newLinks.length; i++) {
      const newLink = newLinks[i];
      newLink.addEventListener("mouseenter", () => {
        // @ts-ignore
        newLink.children[1].style.display = "block";
      });
      newLink.addEventListener("mouseleave", () => {
        // @ts-ignore
        newLink.children[0].style.animation =
          "normalaReverse 1s ease-in forwards";
        // @ts-ignore
        newLink.children[1].style.animation =
          "rotateaReverse 1s ease-in forwards";
        setTimeout(() => {
          // @ts-ignore
          newLink.children[0].style.animation = "";
          // @ts-ignore
          newLink.children[1].style.animation = "";
        }, 1000);
      });
    }
  }, [links]);

  useEffect(() => {
    if (checked) {
      document.ontouchmove = (event) => {
        if (event.touches.length === 1) {
          event.preventDefault();
        }
      };
      document.body.style.overflow = "hidden";
    } else {
      document.ontouchmove = (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
      };
      document.body.style.overflow = "initial";
    }
  }, [checked]);

  return (
    <nav>
      <input
        id="hamburger"
        className="hamburger"
        type="checkbox"
        checked={checked}
        onChange={handleClick}
      />
      <label id="hamburgerLabel" className="hamburger" htmlFor="hamburger">
        {/* <i></i> */}
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </label>
      <section className="drawer-list">
        <div className="lang">
          {isBrowser && (
            <ul>
              {languages.map((language) => (
                <li
                  key={language.key}
                  className={`lang-button${
                    currentLocale === language.key ? " active" : ""
                  }`}
                  onClick={() => handleLanguageClick(language.key)}
                >
                  {language.text}
                </li>
              ))}
            </ul>
          )}
        </div>
        <ul>
          {links.map((link) =>
            link.href ? (
              <li key={link.text}>
                <ActiveLink
                  activeClassName="active-link"
                  onClick={handleClose}
                  href={link.href}
                  passHref
                >
                  <a className="link">
                    <span className="normal">{link.text}</span>
                    <span className="rotate">{link.text}</span>
                  </a>
                </ActiveLink>
              </li>
            ) : (
              <li key={link.text}>
                <a className="link" href="#" onClick={link.onClick}>
                  <span className="normal">{link.text}</span>
                  <span className="rotate">{link.text}</span>
                </a>
              </li>
            )
          )}
        </ul>
      </section>
      <CSSTransition
        in={isPopupOpen}
        timeout={{ enter: 500, exit: 500, appear: 500 }}
        unmountOnExit
        classNames={"smooth"}
      >
        <PopupContactUs onClose={() => setIsPopupOpen(false)} />
      </CSSTransition>
      {/* {isPopupOpen && <PopupContactUs onClose={() => setIsPopupOpen(false)} />} */}
    </nav>
  );
};

export default Navigation;
