export const addEventListenersToHeader = () => {
  // const links = document.getElementsByClassName('link');
  // for (let i = 0; i < links.length; i++) {
  //   const link = links[i];
  //   link.addEventListener('mouseenter', () => {
  //     link.children[1].style.display = 'block';
  //   });
  //   link.addEventListener('mouseleave', () => {
  //     link.children[0].style.animation = 'normalaReverse 1s ease-in forwards';
  //     link.children[1].style.animation = 'rotateaReverse 1s ease-in forwards';
  //     setTimeout(() => {
  //       link.children[0].style.animation = '';
  //       link.children[1].style.animation = '';
  //     }, 1000);
  //   });
  // }

  const updateMode = (mode) => {
    if (mode === 'dark' || (typeof mode !== 'string' && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
      root.style.setProperty('--white', '#111');
      root.style.setProperty('--black', '#fff');
      root.style.setProperty('--toastify-color-light', '#121212');
      root.style.setProperty('--toastify-text-color-light', '#fff');

      localStorage.setItem('mode', 'dark');
    } else {
      root.style.setProperty('--white', '#fff');
      root.style.setProperty('--black', '#000');
      root.style.setProperty('--toastify-color-light', '#fff');
      root.style.setProperty('--toastify-text-color-light', '#757575');

      localStorage.setItem('mode', 'light');
    }
  };

  const toggleMode = document.getElementById('button-moon1');
  const root = document.documentElement;
  if (toggleMode && root) {
    const lastMode = localStorage.mode;
    if (lastMode === 'dark' || (!lastMode && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
      toggleMode.setAttribute('checked', '');
    } else {
      toggleMode.removeAttribute('checked');
    }
    updateMode(lastMode);

    toggleMode.addEventListener('click', (event) => {
      const dark = event.target.checked;
      updateMode(dark ? 'dark' : 'light');
    });
  }
};
