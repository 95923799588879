import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './public/locales/en.json';
import uk from './public/locales/uk.json';

export const resources = {
  en,
  uk,
} as const;

export const locales = Object.keys(resources);
export const getCurrentLocale = () =>
  i18n.languages.find((language) => locales.indexOf(language) !== -1);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    preload: locales,
    fallbackLng: {
      'ru-UA': ['uk'],
      default: ['uk'],
    },
  });
