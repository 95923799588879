import Link from "next/link";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { VacancyApi } from "../api/api";
import { IVacancy } from "../utils/types";
import ActiveLink from "./ActiveLink";
import { CSSTransition } from "react-transition-group";
import PopupContactUs from "./PopupContactUs";

interface ILinkItem {
  onClick?: () => void;
  href?: string;
  text: string;
}

export const Footer = () => {
  const { t } = useTranslation();
  const [vacancies, setVacancies] = useState<Array<IVacancy> | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const getVacanciesList = async () => {
    const list = await VacancyApi.getVacancyList();
    setVacancies(list);
  };

  useEffect(() => {
    getVacanciesList();
  }, []);

  const links: ILinkItem[] = useMemo(
    () => [
      { href: "/about", text: t("navigation:about") },
      { href: "/services", text: t("navigation:services") },
      { href: "/portfolio", text: t("navigation:portfolio") },
      { href: "/vacancies", text: t("navigation:vacancies") },
      { href: "/blog", text: t("navigation:blog") },
      { onClick: () => setIsPopupOpen(true), text: t("navigation:contactUs") },
    ],
    [t]
  );

  return (
    <footer>
      <div className="footer-content">
        <div className="footer-block1">
          <p>
            Oleksandra Polia Ave, 58
            <br />
            49000
            <br />
            Dnipro, Ukraine
          </p>
        </div>
        <div className="footer-block2">
          {/* <ul>
            <li>
              <a href="#">Facebook</a>
            </li>
            <li>
              <a href="#">Instagram</a>
            </li>
            <li>
              <a href="#">Twitter</a>
            </li>
            <li>
              <a href="#">Behance</a>
            </li>
            <li>
              <a href="#">LinkedIn</a>
            </li>
            <li>
              <a href="#">Medium</a>
            </li>
         </ul>*/}
          <ul>
            {links.map((link) =>
              link.href ? (
                <li key={link.text}>
                  <a href={link.href}>{link.text}</a>
                </li>
              ) : (
                <li key={link.text}>
                  <span onClick={link.onClick}>{link.text}</span>
                </li>
              )
            )}
          </ul>
        </div>
        <div className="footer-block3">
          <p>{t("footer:vacancies")}:</p>
          {vacancies && vacancies.length > 0 && (
            <ul>
              {vacancies.map((item, index) => {
                return (
                  <li key={item.id} className={"vacancy-title"}>
                    <Link
                      key={item.id}
                      href="/vacancies/[id]"
                      as={`/vacancies/${item.name
                        .replace(/\s/g, "_")
                        .replace(/\//g, "|")}`}
                      passHref
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="footer-copirite">
          <p>
            © 2010—{new Date().getFullYear()} JellyWorkz.
            <br /> Made in Dnipro, Ukraine
            <br />
          </p>
        </div>
      </div>
      <CSSTransition
        in={isPopupOpen}
        timeout={{ enter: 500, exit: 500, appear: 500 }}
        unmountOnExit
        classNames={"smooth"}
      >
        <PopupContactUs onClose={() => setIsPopupOpen(false)} />
      </CSSTransition>
    </footer>
  );
};
