import { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { addEventListenersToHeader } from "../utils";
import Navigation from "./Navigation";

const Header = () => {
  const [checked, setChecked] = useState(false);

  const handleClick = useCallback(() => {
    setChecked((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
      setChecked(false);
  }, []);

  useEffect(() => {
    addEventListenersToHeader();
  }, []);

  return (
    <header>
      <div className="logo">
        <Link href="/" passHref>
          <a onClick={handleClose}>
            <Image
              src="/logo.svg"
              alt="logo"
              layout={'fixed'}
              width={118}
              height={48}
              priority
            />
            {/* <img src="/logo.svg" alt="logo" width={118} height={48} /> */}
          </a>
        </Link>
      </div>
      <div className="navigation-menu">
        <div className="moon-toggle">
          <input className="button-moon" id="button-moon1" type="checkbox" />
          <label className="button-moon-for" htmlFor="button-moon1"></label>
        </div>
        <Navigation
          checked={checked}
          handleClick={handleClick}
          handleClose={handleClose}
        />
      </div>
    </header>
  );
};

export default Header;
