import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ToastContainer } from "react-toastify";
import Layout from '../components/Layout';
import "react-toastify/dist/ReactToastify.css";
import '../i18n';
import '../styles/main.scss';
import '../styles/responsive.scss';
import '../styles/jelly.scss';
import '../styles/quill.css';

const MyApp = ({ Component, pageProps }: AppProps) => {
  // React.useEffect(() => {
  //   const html = document.querySelector('html')
  //   if (html) {
  //     html.setAttribute('dir', 'rtl')
  //   }
  // }, [])

  return (
    <>
      <Head>
        <title>Jellyworkz</title>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="Creation of websites and mobile applications."></meta>
        <link rel="icon" href="/logo.png" type="image/svg+xml" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/logo.png" />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
      <ToastContainer
        position="bottom-right"
      />
    </>
  );
};

export default MyApp;
