import axios from 'axios';
import instance from './instance';


const createFormData = (body: { [key: string]: string | number | Blob }) => {
    const formData = new FormData()
    for (const [key, value] of Object.entries(body)) {
        if (key === 'file') {
            formData.append(key, value as File)
        }
        else if (value) {
            formData.append(key, value.toString())
        }
    }
    return formData
}

export const PortfolioApi = {
    getPortfolioList() {
        return instance
            .get('/portfolio')
            .then(response => response.data)
            .catch(() => false)
    }
}

export const VacancyApi = {
    getVacancyList() {
        return instance
            .get('/vacancy')
            .then(response => response.data)
            .catch(() => false)
    }
}

export const BlogApi = {
    getBlogList() {
        return instance
            .get('/blog')
            .then(response => response.data)
            .catch(() => false)
    }
}

export const OldApi = {
    vacancyApply(body: { name: string, email: string, phone: number, file: Blob, }) {
        return axios
            .post(`${process.env.oldUrl}/apply-vacancy`, createFormData(body))
            .then(response => response.data)
            .catch(() => false)
    },
    contactUs(body: { name: string, email: string, message: string, file?: Blob, }) {
        return axios
            .post(`${process.env.oldUrl}/contact-us`, createFormData(body))
            .then(response => response.data)
            .catch(() => false)
    }
}