import axios from "axios";

const dataAxios = {
    // baseURL: process.env.NODE_ENV !== 'production' ? 'https://' : 'https://',
    baseURL: process.env.baseUrl,
};

const instance = axios.create(dataAxios);

instance.defaults.headers.post["Content-Type"] = "multipart/form-data";
instance.defaults.headers.put["Content-Type"] = "multipart/form-data";
instance.interceptors.response.use(
    response => response,
    error => {
        console.error("[error]", error)
        return Promise.reject(error)
    }
)

export default instance