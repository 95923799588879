import { useRouter } from "next/router";
import Link, { LinkProps } from "next/link";
import React, { Children, MutableRefObject, useEffect } from "react";

interface ActiveLink extends LinkProps {
  children: any;
  onClick: () => void;
  activeClassName: string;
}

const ActiveLink: React.FC<ActiveLink> = ({
  children,
  activeClassName,
  onClick,
  ...props
}) => {
  const { asPath } = useRouter();
  const child = Children.only(children);
  const childClassName = child.props.className || "";

  // pages/index.js will be matched via props.href
  // pages/about.js will be matched via props.href
  // pages/[slug].js will be matched via props.as
  const className =
    asPath === props.href || asPath === props.as
      ? `${childClassName} ${activeClassName}`.trim()
      : childClassName;

  useEffect(() => {
    onClick();
  }, [className]);

  return (
    <Link {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </Link>
  );
};

export default ActiveLink;
